import { lazy } from "react";
import { Navigate } from "react-router-dom";
import City from "../views/ui/City.js";
import Timing from "../views/ui/Timing.js";
// import Booking from "../views/ui/Booking.js";
import Pricing from "../views/ui/Pricing.js";
import Tablo from "../views/ui/Tablo.js";
import MyComponent from "../views/ui/MyComponent.js";
import BookingT from "../views/ui/Booking.js";
import CreateBooking from "../views/ui/CreateBooking.js";
import Private from "../views/Private.js";
import LoginUI from "../views/ui/LoginUI.js";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));

/***** Pages ****/

const Starter = lazy(() => import("../views/Starter.js"));
// const About = lazy(() => import("../views/About.js"));
// const Alerts = lazy(() => import("../views/ui/Alerts"));
// const Badges = lazy(() => import("../views/ui/Badges"));
// const Buttons = lazy(() => import("../views/ui/Buttons"));
// const Cards = lazy(() => import("../views/ui/Cards"));
// const Grid = lazy(() => import("../views/ui/Grid"));
const Tables = lazy(() => import("../views/ui/Tables"));
const PCreate = lazy(() => import("../views/ui/patriata/CreatePBooking.js"));
const Booking = lazy(() => import("../views/ui/Booking"));
const Forms = lazy(() => import("../views/ui/Forms"));
const PCity = lazy(() => import("../views/ui/patriata/PCity"));
const PBooking = lazy(() => import("../views/ui/patriata/PBooking.js"));
const PTour = lazy(() => import("../views/ui/patriata/PTour.js"));
const PForm = lazy(() => import("../views/ui/patriata/PForms.js"));
const PPricing = lazy(() => import("../views/ui/patriata/PPricing.js"));
const Login = lazy(() => import("../views/ui/Login.js"));
const BCity = lazy(() => import("../views/ui/boating/BCity.js"));
const BTables = lazy(() => import("../views/ui/boating/BTables.js"));
const BPricing = lazy(() => import("../views/ui/boating/BPricing.js"));
const BTiming = lazy(() => import("../views/ui/boating/BTiming.js"));
const BForm = lazy(() => import("../views/ui/boating/BForms.js"));
const BBooking = lazy(() => import("../views/ui/boating/BBooking.js"));
const BCreate = lazy(() => import("../views/ui/boating/CreateBooking.js"));
// const Breadcrumbs = lazy(() => import("../views/ui/Breadcrumbs"));

/*****Routes******/

const ThemeRoutes = [
  { element: <Private /> },
  { path: "/login", element: <LoginUI /> },
  { path: "/t2", exact: true, element: <Login /> },
  {
    path: "/",
    element: <FullLayout />,
    children: [
      { path: "/starter", exact: true, element: <Starter /> },
      // { path: "/about", exact: true, element: <About /> },
      // { path: "/alerts", exact: true, element: <Alerts /> },
      { path: "/city", element: <City /> },
      { path: "/pcreate", element: <PCreate /> },
      { path: "/timing", exact: true, element: <Timing /> },
      { path: "/pcity", exact: true, element: <PCity /> },
      { path: "/ptour", exact: true, element: <PTour /> },
      { path: "/pform", exact: true, element: <PForm /> },
      { path: "/pbooking", exact: true, element: <PBooking /> },
      { path: "/ppricing", exact: true, element: <PPricing /> },
      { path: "/bpricing", exact: true, element: <BPricing /> },
      { path: "/btiming", exact: true, element: <BTiming /> },
      { path: "/bforms", exact: true, element: <BForm /> },
      { path: "/bbooking", exact: true, element: <BBooking /> },
      { path: "/pricing", exact: true, element: <Pricing /> },
      { path: "/create", exact: true, element: <CreateBooking /> },
      // { path: "/t", exact: true, element: <BookingT /> },

      { path: "/bcity", exact: true, element: <BCity /> },
      { path: "/btable", exact: true, element: <BTables /> },
      { path: "/bcreate", exact: true, element: <BCreate /> },
      // { path: "/buttons", exact: true, element: <Buttons/> },
      // { path: "/tablo", exact: true, element: <Tablo /> },
      { path: "/booking", exact: true, element: <Booking /> },
      { path: "/table", exact: true, element: <Tables /> },
      // { path: "/dataT", exact: true, element: <MyComponent/> },
      { path: "/forms", exact: true, element: <Forms /> },
      { path: "/", element: <Starter /> },
      // { path: "/breadcrumbs", exact: true, element: <Breadcrumbs /> },
    ],
  },
];

export default ThemeRoutes;
