//import { ReactComponent as LogoDark } from "../assets/images/logos/adminpro.svg";
import { ReactComponent as LogoDark } from "../assets/images/logos/download.svg";
import { Link } from "react-router-dom";
import download from "./turo.png"
const Logo = () => {
  const Route=sessionStorage.getItem("Route");
  
  return (
    <Link to="/">
     {/* <LogoDark /> */}
     
      <img src={download} style={{width:'160px',height:'50px'}}/>
    </Link>
  );
};

export default Logo;
