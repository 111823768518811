import { Col, Row } from "reactstrap";
import SalesChart from "../components/dashboard/SalesChart";
import Feeds from "../components/dashboard/Feeds";
import ProjectTables from "../components/dashboard/ProjectTable";

import Blog from "../components/dashboard/Blog";
import bg1 from "../assets/images/bg/bg1.jpg";
import bg2 from "../assets/images/bg/bg2.jpg";
import bg3 from "../assets/images/bg/bg3.jpg";
import bg4 from "../assets/images/bg/bg4.jpg";
import SalesChartP from "../components/dashboard/SalesChartP";
import SalesChartB from "../components/SalesChartB";
// import { useState ,useEffect} from "react";

const BlogData = [
  {
    image: bg1,
    title: "This is simple blog",
    subtitle: "2 comments, 1 Like",
    description:
      "This is a wider card with supporting text below as a natural lead-in to additional content.",
    btnbg: "primary",
  },
  {
    image: bg2,
    title: "Lets be simple blog",
    subtitle: "2 comments, 1 Like",
    description:
      "This is a wider card with supporting text below as a natural lead-in to additional content.",
    btnbg: "primary",
  },
  {
    image: bg3,
    title: "Don't Lamp blog",
    subtitle: "2 comments, 1 Like",
    description:
      "This is a wider card with supporting text below as a natural lead-in to additional content.",
    btnbg: "primary",
  },
  {
    image: bg4,
    title: "Simple is beautiful",
    subtitle: "2 comments, 1 Like",
    description:
      "This is a wider card with supporting text below as a natural lead-in to additional content.",
    btnbg: "primary",
  },
];

const Starter = () => {
  const Route = sessionStorage.getItem("Route");
  console.log(Route);
  return (
    <div style={{ width: "100%" }}>
      {/***Top Cards***/}

      {/***Sales & Feed***/}
      {Route == "Sight Seeing" || Route == "All" ? (
        <Row>
          <Col sm="6" lg="6" xl="7" xxl="8" style={{ width: "100%" }}>
            <SalesChart />
          </Col>

          {/* <Col sm="6" lg="6" xl="5" xxl="4">
          <Feeds />
        </Col> */}
        </Row>
      ) : null}
      
      {/* {Route == "Patriata ChairLift" || Route == "All" ? (
        <Row>
          <Col>
            <SalesChartP />
          </Col>
        </Row>
      ) : null}
      {Route == "Boating Service" || Route == "All" ? (
        <Row>
          <Col>
            <SalesChartB />
          </Col>
        </Row>
      ) : null} */}

      {/***Table ***/}
      {/* <Row>
        <Col lg="12">
          <ProjectTables />
        </Col>
      </Row> */}
      {/***Blog Cards***/}
      {/* <Row>
        {BlogData.map((blg, index) => (
          <Col sm="6" lg="6" xl="3" key={index}>
            <Blog
              image={blg.image}
              title={blg.title}
              subtitle={blg.subtitle}
              text={blg.description}
              color={blg.btnbg}
            />
          </Col>
        ))}
      </Row> */}
    </div>
  );
};

export default Starter;
