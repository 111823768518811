import React, { useState, useEffect } from "react";
import { Row, Col, Table, Card, CardTitle, CardBody, Button } from "reactstrap";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Form,
  FormGroup,
} from "reactstrap";
import {API_URL} from '../../API_CREDIENTIALS'
import { useLocation } from "react-router-dom";
function Pricing() {
  const location = useLocation();
  const { id } = location.state;
  const [editId, setEditID] = useState("");
  const init = {
    desc: "",
    price: "",
  };
  const [timing, setTiming] = useState({
    desc: "",
    price: "",
  });
  const handleChange = (e) => {
    console.log(e);
    const { name, value } = e.target;
    setTiming((prevFormData) => ({ ...prevFormData, [name]: value }));
    console.log(name, value);
    console.log(timing);
  };
  const get = async () => {
    await fetch(`${API_URL}/get_pricing?routeId=${id}`)
      .then((res) => res.json())
      .then((data) => setRow(data.data));
  };
  const saveTiming = async () => {
    console.log(timing);
    await fetch(`${API_URL}/add_pricing?desc=${timing.desc}&price=${timing.price}&route_id=${id}`)
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        if (data.status == 200) {
          alert("Added Succesfully");
          setTiming(init);
          setModal(!module);
          get();
        } else {
          alert(data.message);
        }
      });

    // window.alert("Data Inserted Succesfully");
  };
  const deleteTiming = (id) => {
    console.log(id);
    if (window.confirm("All Tours Will be also Deleted?")) {
      //  https://tdcp.gop.pk/apis/update_pricing?method=delete&id=35
      try {
        fetch(`${API_URL}/update_pricing?method=delete&id=${id}`)
          .then((res) => res.json())
          .then((data) => {
            // console.log(data);
            data.status == 200
              ? alert("Deleted Succesfully")
              : alert(data.message);
          });
        alert("Deleted Succesfully");
        get();
      } catch (e) {
        console.log(e);
      }
    }
  };
  const editTiming = (id, desc, price) => {
    console.log(id);
    let update = {
      desc: desc,
      price: price,
    };
    setTiming((st) => ({
      ...st,
      ...update,
    }));
    setEditID(id);
    setModal(!modal);
  };
  const updateTiming = () => {
    console.log(editId);
    if (editId) {
      //  https://tdcp.gop.pk/apis/update_pricing?method=update&id=&desc=&price=&route_id=
      // console.log(id);
      fetch(`${API_URL}/update_pricing?method=update&id=${editId}&desc=${timing.desc}&price=${timing.price}`)
        .then((res) => res.json())
        .then((data) => {
          // console.log(data);
          data.status == 200
            ? alert("Updated Succesfully")
            : alert(data.message);
        });
      // alert("Updated Succesfully");
      setModal(!modal);
      setTiming(init);
      setEditID("");
      get();
    } else {
      alert("Click on Add Button");
    }
  };
  // console.log(id+ " Id is");
  const [row, setRow] = useState([]);
  const [modal, setModal] = useState(false);
  const [unmountOnClose, setUnmountOnClose] = useState(true);
  const toggle = () => setModal(!modal);
  const changeUnmountOnClose = (e) => {
    let { value } = e.target;
    setUnmountOnClose(JSON.parse(value));
  };

  useEffect(() => {
    get();
    console.log("Total Data is");
    console.log(row);
  }, [id]);
  return (
    <div>
      <Row>
        <Col lg="12">
          <Card>
            <CardTitle tag="h6" className="border-bottom p-3 mb-0">
              <i className="bi bi-card-text me-2"> </i>
              Pricing Detail
            </CardTitle>
            <CardBody className="">
              <div className="button-group">
                <Button color="danger" onClick={toggle}>
                  Add New Pricing
                </Button>
              </div>
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Description</th>
                    <th>Price</th>
                    <th>Route Id</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    // row.map((r)=>r.timings.map((t)=>console.log(t)))
                  }
                  {row.map((route) => {
                    return (
                      <tr key={route.id}>
                        <td>{route.id}</td>
                        <td>{route.desc}</td>
                        <td>{route.price}</td>
                        <td>{route.route_id}</td>

                        <td>
                          <div className="button-group">
                            <Button
                              color="danger bi-pencil-square"
                              onClick={(e) =>
                                editTiming(route.id, route.desc, route.price)
                              }
                            ></Button>
                            <Button
                              color="danger bi-trash"
                              onClick={(e) => deleteTiming(route.id)}
                            ></Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal isOpen={modal} toggle={toggle} unmountOnClose={unmountOnClose}>
        <ModalHeader toggle={toggle}>Pricing</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="exampleEmail">Description</Label>
              <Input
                id="exampleEmail"
                name="desc"
                placeholder="Enter Description"
                type="text"
                value={timing.desc}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">Price</Label>
              <Input
                id="exampleEmail"
                name="price"
                placeholder="price"
                type="number"
                value={timing.price}
                onChange={handleChange}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={saveTiming}>
            Add Pricing
          </Button>{" "}
          <Button color="danger" onClick={updateTiming}>
            Update
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Pricing;
