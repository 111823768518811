import React, { useState, useEffect } from "react";
import { Row, Col, Table, Card, CardTitle, CardBody, Button } from "reactstrap";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Form,
  FormGroup,
} from "reactstrap";
import { API_URL } from "../../API_CREDIENTIALS";
function City() {
  const [row, setRow] = useState([]);
  const [modal, setModal] = useState(false);
  const [city, setCity] = useState("");
  const [id, setEditID] = useState("");
  const [active, setActive] = useState(1);
  const [unmountOnClose, setUnmountOnClose] = useState(true);
  const Citypermission = sessionStorage.getItem("City");
  const toggle = () => setModal(!modal);

  //Save City
  const saveCity = async () => {
    const cty = row.filter((c) => c.name === city);
    if (cty.length > 0) {
      alert("Already Exist");
    } else {
      await fetch(`${API_URL}/add_city?name=${city}&active=${active}`)
        .then((res) => res.json())
        .then((data) => {
          // console.log(data);
          if (data.status == 200) {
            alert("Added Succesfully");
            setCity("");
            setActive("");
            setModal(!modal);
            get();
          } else {
            alert(data.message);
          }
        });
    }
  };
  //for Updating City
  const updateCity = async () => {
    // console.log(active);
    if (id) {
      // console.log(id);
      await fetch(`${API_URL}/update_cities?method=update&id=${id}&name=${city}&active=${active}`)
        .then((res) => res.json())
        .then((data) => {
          // console.log(data);
          data.status == 200
            ? alert("Updated Succesfully")
            : alert(data.message);
        });
      // alert("Updated Succesfully");
      setModal(!modal);
      setCity("");
      setActive("");
      setEditID("");
      get();
    } else {
      alert("Click on Add Button");
    }
  };

  //Delete City
  const deleteCity = async (id) => {
    if (window.confirm("All Tours Will be also Deleted?")) {
      // console.log(id);
      await fetch(`${API_URL}/update_cities?method=delete&id=${id}`)
        .then((res) => res.json())
        .then((data) => {
          // console.log(data);
          data.status == 200
            ? alert("Deleted Succesfully")
            : alert(data.message);
        });
      //.then(data => console.log(data));
      // alert("Deleted Succesfully");
      get();
    }
  };

  //Edit City
  const editCity = (id, city, active) => {
    // console.log(id);
    setEditID(id);
    setCity(city);
    setActive(active == 1 ? "1" : "0");
    setModal(!modal);
  };

  const changeUnmountOnClose = (e) => {
    let { value } = e.target;
    setUnmountOnClose(JSON.parse(value));
  };

  //Fetching Data
  const get = async () => {
    await fetch(`${API_URL}/get_cities`)
      .then((res) => res.json())
      .then((data) => {
        console.log(Citypermission);
        console.log(data.data.find((d) => d.name));
        if (Citypermission == "All") {
          console.log("All");
          setRow(data.data);
        } else if (data.data.filter((d) => d.name === Citypermission)) {
          const filter = data.data.filter((d) => {
            return d.name === Citypermission;
          });
          setRow(filter);
        }
      });
  };

  useEffect(() => {
    get();
  }, []);

  return (
    <div>
      <Row>
        <Col lg="12">
          <Card>
            <CardTitle tag="h6" className="border-bottom p-3 mb-0">
              <i className="bi bi-card-text me-2"> </i>
              City Detail
            </CardTitle>
            <CardBody className="">
              <div className="button-group">
                {Citypermission == "All" ? (
                  <Button color="danger" onClick={toggle}>
                    Add New City
                  </Button>
                ) : (
                  ""
                )}
              </div>
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Active</th>
                    <th>Tours</th>
                    <th className="text-end">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {console.log(row)}
                  {row.map((route) => {
                    return (
                      <tr key={route.id}>
                        <td>{route.id}</td>
                        <td>{route.name}</td>
                        <td>{route.active == 1 ? "Active" : "InActive"}</td>
                        <td>{route.tours}</td>
                        <td>
                          <div className="button-group text-end">
                            <Button
                              color="danger bi-pencil-square"
                              onClick={(e) =>
                                editCity(route.id, route.name, route.active)
                              }
                            ></Button>
                            <Button
                              color="danger bi-trash"
                              onClick={(e) => deleteCity(route.id)}
                            ></Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal isOpen={modal} toggle={toggle} unmountOnClose={unmountOnClose}>
        <ModalHeader toggle={toggle}>Modal title</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="exampleEmail">Name *</Label>
              <Input
                id="exampleEmail"
                name="name"
                placeholder="Enter a Name"
                type="text"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleSelectMulti">Active {active}</Label>
              <Input
                id="exampleSelect"
                name="select"
                type="select"
                value={active}
                onChange={(e) => setActive(e.target.value)}
              >
                <option value={1}>Active</option>
                <option value={0}>InActive</option>
              </Input>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={saveCity}>
            Add
          </Button>{" "}
          <Button color="danger" onClick={updateCity}>
            Update
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default City;
